import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faForward, faBackward, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { GatsbyImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink";

class Player extends React.Component  {
  state = {
    index: 0,
    currentTime: '0:00',
    testing: "monkey",
    musicList: [
      {
        name:'The Christmas Song',
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/01TheChristmasSong.m4a',
        duration: '2:02'
      },
      {
        name:'This Baby',
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/02ThisBaby.m4a',
        duration: '2:02'
      },
      {
        name:'Silent Night',
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/03SilentNight.m4a',
        duration: '2:02'
      },
      {
        name:'Silent Night',
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/04ChristmasIsAllInTheHeart.m4a',
        duration: '2:02'
      },
      {
        name:'Christmas Is All In TheHeart',
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/04ChristmasIsAllInTheHeart.m4a',
        duration: '2:02'
      },
      {
        name:"O'Holy Night",
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/05OHolyNight.m4a',
        duration: '2:02'
      },
      {
        name:"One Bright Star",
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/07OneBrightStar.m4a',
        duration: '2:02'
      },
      {
        name:"Let There Be Peace On Earth",
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/08LetThereBePeaceOnEarth.m4a',
        duration: '2:02'
      },
      {
        name:"I'll Be Home For Christmas",
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/09IllBeHomeForChristmas.m4a',
        duration: '2:02'
      },
      {
        name:"10 Best Christmas",
        author: 'Steve Clark',
        img: '/cover.jpg',
        audio:'/10BestChristmas.m4a',
        duration: '2:02'
      },
    ],
    pause: false,
  };


 componentDidMount() {
   this.playerRef.addEventListener("timeupdate", this.timeUpdate, false);
   this.playerRef.addEventListener("ended", this.nextSong, false);
   this.timelineRef.addEventListener("click", this.changeCurrentTime, false);
   this.timelineRef.addEventListener("mousemove", this.hoverTimeLine, false);
   this.timelineRef.addEventListener("mouseout", this.resetTimeLine, false);
 }

  componentWillUnmount() {
    this.playerRef.removeEventListener("timeupdate", this.timeUpdate);
    this.playerRef.removeEventListener("ended", this.nextSong);
    this.timelineRef.removeEventListener("click", this.changeCurrentTime);
    this.timelineRef.removeEventListener("mousemove", this.hoverTimeLine);
    this.timelineRef.removeEventListener("mouseout", this.resetTimeLine);
  }

changeCurrentTime = (e) => {
  const duration = this.playerRef.songLength;

  const playheadWidth = this.timelineRef.offsetWidth;
  const offsetWidht = this.timelineRef.offsetLeft;
  const userClickWidht = e.clientX - offsetWidht;

  const userClickWidhtInPercent = (userClickWidht*100)/playheadWidth;

  this.playheadRef.style.width = userClickWidhtInPercent + "%";
  this.playerRef.currentTime = (duration * userClickWidhtInPercent)/100;
}

hoverTimeLine = (e) => {
  const duration = this.playerRef.songLength;

  const playheadWidth = this.timelineRef.offsetWidth

  const offsetWidht = this.timelineRef.offsetLeft;
  const userClickWidht = e.clientX - offsetWidht;
  const userClickWidhtInPercent = (userClickWidht*100)/playheadWidth;

  if(userClickWidhtInPercent <= 100){
    this.hoverPlayheadRef.style.width = userClickWidhtInPercent + "%";
  }

  const time = (duration * userClickWidhtInPercent)/100;

  if( (time >=0) && (time <= duration)){
    this.hoverPlayheadRef.dataset.content = this.formatTime(time);
  }
}

resetTimeLine = () => {
  this.hoverPlayheadRef.style.width = 0;
}

timeUpdate = () => {
  const duration = this.playerRef.songLength;
  const timelineWidth = this.timelineRef.offsetWidth - this.playheadRef.offsetWidth;
  const playPercent = 100 * (this.playerRef.currentTime / duration);
	this.playheadRef.style.width = playPercent + "%";
  const currentTime = this.formatTime(parseInt(this.playerRef.currentTime));
  this.setState({
    currentTime
  });
}

formatTime = (currentTime) =>{
  const minutes = Math.floor(currentTime / 60);
  let seconds = Math.floor(currentTime % 60);

  seconds = (seconds >= 10) ? seconds : "0" + seconds % 60;

  const formatTime = minutes + ":" +  seconds

  return formatTime;
  }

  updatePlayer = () =>{
    const { musicList, index } = this.state;
    const currentSong = musicList[index];
    const audio = new Audio(currentSong.musicFile.localFile.url);
    this.playerRef.load();
  }

  nextSong = () => {
    const { musicList, index, pause } = this.state;

    this.setState({
      index: (index + 1) % musicList.length
    });
    this.updatePlayer();
    if(pause){
      this.playerRef.play();
    }
  };

  prevSong = () => {
    const { musicList, index, pause } = this.state;

    this.setState({
      index: (index + musicList.length - 1) % musicList.length
    });
    this.updatePlayer();
    if(pause){
      this.playerRef.play();
    }
  };


  playOrPause = () =>{
    const { musicList, index, pause } = this.state;
    const currentSong = musicList[index];
    const audio = new Audio(currentSong.musicFile.localFile.url);
    if( !this.state.pause ){
      this.playerRef.play();
    }else{
      this.playerRef.pause();
    }
    this.setState({
      pause: !pause
    })
  }

  clickAudio = (key) =>{
    const { pause } = this.state;

    this.setState({
      index: key
    });

    this.updatePlayer();
    if(pause){
      this.playerRef.play();
    }
  }


  render() {
    this.state.musicList = this.props.acf.songs;

    const { musicList, index, currentTime, pause } = this.state;
    const currentSong = musicList[index];
    return (
      <div className="card">
        <div className="current-song">
          <audio ref={ref => this.playerRef = ref}>
            <source src={ currentSong.musicFile.localFile.url } type="audio/mpeg"/>
              Your browser does not support the audio element.
          </audio>
          <AniLink className="back" swipe direction="right" to="/" exit="bottom" entryOffset={180} duration={0.4}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </AniLink>
          <div className="img-wrap">
            <GatsbyImage
                   image={this.props.acf.coverImage.localFile.childImageSharp.gatsbyImageData}
                   alt={this.props.acf.coverImage.altText}

                   />
           </div>
          <span className="song-name">{ currentSong.title }</span>
          <span className="song-autor">Steve Clark</span>

          <div className="time">
            <div className="current-time">{ currentTime }</div>
            <div className="end-time">{ currentSong.songLength }</div>
          </div>

          <div ref={ref => this.timelineRef = ref} id="timeline">
            <div ref={ref => this.playheadRef = ref} id="playhead"></div>
            <div ref={ref => this.hoverPlayheadRef = ref} class="hover-playhead" data-content="0:00"></div>
          </div>

          <div className="controls">
            <button onClick={this.prevSong} className="prev prev-next current-btn"><FontAwesomeIcon icon={faBackward} /></button>

            <button onClick={this.playOrPause} className="play current-btn">
              {
                (!pause) ? <FontAwesomeIcon icon={faPlay} />
                : <FontAwesomeIcon icon={faPause} />
              }
            </button>
            <button onClick={this.nextSong} className="next prev-next current-btn"><FontAwesomeIcon icon={faForward} /></button>
          </div>

        </div>
        <div className="play-list" >
          {musicList.map( (music, key=0) =>
           <div key={key}
             onClick={()=>this.clickAudio(key)}
             className={"track " +
               (index === key && !pause ?'current-audio':'') +
               (index === key && pause ?'play-now':'')} >

             <GatsbyImage
                    image={this.props.acf.coverImage.localFile.childImageSharp.gatsbyImageData}
                    alt={this.props.acf.coverImage.altText}
                    className="track-img"
                    />
             <div className="track-discr" >
               <span className="track-name" >{music.title}</span>
               <span className="track-author" >Steve Clark</span>
             </div>
             <span className="track-duration" >
               {(index === key)
                 ?currentTime
                 :music.songLength
               }
             </span>
           </div>
          )}
        </div>
      </div>
    )
  }
}

export default Player
