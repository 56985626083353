import React from "react"
import { graphql } from "gatsby"
import Player from "../components/player"

export const query = graphql`
  query($id: String) {
    wpAlbum(id: { eq: $id }) {
      id
      title
      albumACF {
        coverImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 375, height: 250)
            }
          }
        }
        songs {
          musicFile {
            localFile {
              url
            }
          }
          songLength
          title
        }
      }
    }
  }
`

const WpAlbum = ({  data: {
    wpAlbum: { title, albumACF },
  }}) => {
  return (

    <div><Player title={title} acf={albumACF} /></div>
  )
}

export default WpAlbum
